<template>
  <div>
    <help :title="'Statistiques Screen AI'">
      <template v-slot:content>
        <p>🎯 Une tentative d'appel signifie qu'un appel Screen AI a essayé de se lancer.</p>
        <p>✅ Un candidat ayant été au bout de l'appel a répondu à au moins une question.</p>
        <p>☎️ Un candidat est considéré comme ayant raccroché si il a raccroché pendant la sonnerie ou durant les 15 premières secondes de l'appel.</p>
        <p>🔇 Un candidat muet est un candidat ayant décroché mais n'ayant rien dit durant l'appel.</p>
        <p>🚫 Un appel échoué est un appel ne s'étant pas lancé, le plus souvent à cause d'un faux numéro.</p>
      </template>
    </help>
    <h1>En résumé</h1>
    <div v-if="busy">
      <spinner></spinner>
    </div>
    <div v-else class="section">
      <div class="inside-section">
        <box
          class="box-left-align"
          description="Candidats envoyés avec au moins une question Screen AI répondue"
        >
          <div class="icon-container">
            <candidates-icon
              width="40px"
              height="40px"
              class="icon"
            ></candidates-icon>
          </div>
          <div class="text-container">
            <p class="stat">{{ sentCandidates }}</p>
            <p class="description">Candidats envoyés</p>
          </div>
        </box>
        <box class="box-left-align" description="Score NPS moyen des candidats">
          <div class="icon-container">
            <smiley-icon
              class="icon smiley"
              width="40px"
              height="40px"
            ></smiley-icon>
          </div>
          <div class="text-container">
            <p v-if="nps" class="stat">{{ Math.round(nps * 10000) / 100 }} %</p>
            <p v-else class="placeholder">Aucune donnée</p>
            <p class="description">Net Promoter Score</p>
          </div>
        </box>
      </div>
      <box :title="'Pour ' + totalCalls + ' tentatives d\'appel'">
        <progress-bar :bars="callStates" :total="totalCalls"></progress-bar>
      </box>
      <box title="Fiabilité de la transcription vocale">
        <simpleDonut
          v-if="transcriptionConfidence"
          :coefficient="transcriptionConfidence"
        ></simpleDonut>
        <p v-else>Aucune information à afficher</p>
      </box>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import Box from '/components/box'
import CandidatesIcon from '/icons/candidates'
import Help from '/components/help'
import ProgressBar from '/reporting/progressBar'
import Recruiter from '/user/recruiter/recruiter.entity.js'
import Search from '/requirement/entity'
import SimpleDonut from '/components/simpleDonut'
import SmileyIcon from '/icons/smiley'
import Spinner from '/layout/spinner'

const CancelToken = axios.CancelToken

export default {
  name: 'hippolyte.reporting.screenAi',
  props: {
    a: Boolean,
    recruiter: Recruiter,
    search: Search,
    start: Date,
    end: Date
  },
  data() {
    return {
      busy: false,
      cancel: null,
      sentCandidates: 0,
      count_calls: 0,
      count_failed_calls: 0,
      callStates: [
        {
          title: "Ont été au bout de l'appel",
          value: 0,
          style: 'success_calls'
        },
        { title: 'N\'ont pas décroché', value: 0, style: 'no_answer_calls' },
        { title: 'Ont raccroché', value: 0, style: 'hangup_calls' },
        { title: 'Muets', value: 0, style: 'no_answers_calls' },
        { title: 'Appels échoués', value: 0, style: 'failed_calls' },
        { title: 'Inconnu', value: 0, style: 'unknown_calls' }
      ],
      nps: 0,
      totalCalls: 0,
      transcriptionConfidence: 0
    }
  },
  watch: {
    search: 'debounce',
    recruiter: 'debounce',
    start: 'debounce',
    end: 'debounce',
    a: 'debounce'
  },
  components: {
    Box,
    CandidatesIcon,
    Help,
    ProgressBar,
    SimpleDonut,
    SmileyIcon,
    Spinner
  },
  computed: {
    test() {
      return 0
    }
  },
  mounted() {
    this.load()
  },
  destroyed() {
    if (this.cancel) {
      this.cancel.cancel()
    }
  },
  methods: {
    async load() {
      this.timeout = null
      this.busy = true
      if (this.cancel) {
        await this.cancel.cancel()
      }
      this.cancel = CancelToken.source()
      try {
        const stats = await Promise.all(
          [
            ['count_sent_candidates'],
            ['get_nps'],
            ['count_calls'],
            ['count_success_calls'],
            ['count_failed_calls', 'no-answer'],
            ['count_failed_calls', 'hangup'],
            ['count_failed_calls', 'no-answers'],
            ['count_failed_calls', 'failed'],
            // ['get_average_confidence'], // TODO Timeout if too much data, must migrate confidence in solr
          ].map(async (item) => await this.requestStat(...item))
        )
        console.log(stats)
        this.sentCandidates = stats[0].count_sent_candidates
        this.nps = stats[1].get_nps
        this.totalCalls = stats[2].count_calls
        this.callStates[0].value = stats[3].count_success_calls
        this.callStates[1].value = stats[4].count_failed_calls
        this.callStates[2].value = stats[5].count_failed_calls
        this.callStates[3].value = stats[6].count_failed_calls
        this.callStates[4].value = stats[7].count_failed_calls
        this.callStates[5].value = this.totalCalls - this.callStates.reduce((acc, item) => acc + item.value, 0)
      } catch (err) {
        if (!axios.isCancel(err)) {
          throw err
        }
      }
      this.cancel = null
      this.busy = false
    },
    requestStat(stat, reason = null) {
      return this.$socket.service(
        'voicebot_stats/GET',
        {
          stat,
          reason,
          start: this.start,
          end: this.end,
          search: this.search ? this.search.id : null,
          recruiter: this.recruiter ? this.recruiter.id : null
        },
        { cancel: this.cancel.token }
      )
    },
    debounce() {
      if (this.timeout) {
        return
      }
      const load = this.load.bind(this)
      this.timeout = setTimeout(load, 10)
    }
  }
}
</script>

<style lang="stylus">
@require '~/colors.styl'

.section
  display flex
  flex-direction row
  align-items flex-start
  gap 24px
  align-self stretch
  .inside-section
    display block
  .box-left-align
    margin-bottom 16px
    .content
      justify-content flex-start

.icon-container
    width 40px
    height 40px
    padding 12px 10px
    gap 10px
    border-radius 4px
    background rgba(22, 78, 226, 0.10)
  .icon
    width 40px
    height 40px
    stroke $hippolyte-blue
    fill: none
  .smiley
    fill $hippolyte-blue
.text-container
  flex-shrink 0
  .stat
    margin 0px
    font-size 24px
    font-weight bolder
  .description
    margin-top 6px
    margin-bottom 0px

.legend_value
  font-size 20px
  font-weight bolder
.legend_title
  float right
  font-size 18px
.bar
  margin-top 8px
  margin-bottom 24px
.progress_bar
  width 100%
  .success_calls
    background-color $hippolyte-blue
  .no_answer_calls
    background-color $hippolyte-orange
  .hangup_calls
    background-color $hippolyte-yellow
  .no_answers_calls
    background-color $hippolyte-green
  .failed_calls
    background-color $hippolyte-black
  .unknown_calls
    background-color $color-raisin

p
  leading-trim both
  text-edge cap
  font-size 20px
  font-family $font
.placeholder
  font-size 15px
  margin-top 0px

h1
  font-size 24px
  font-weight bolder
  margin-bottom 24px
  margin-top 0px
  padding 15px
  padding-left 0px
h1::after
  content ''
  position relative
  background-color $hippolyte-green
  height 5px
  width 50px
  bottom -9px
  display block
</style>
